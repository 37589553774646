export default function Style(){
    return(
      <style>
        {
          `
          *{
            font-family:"Josefin Sans";
          }
          .for-phone-ri8 input.rs-input.rs-input-sm.phone.round-border {
            padding-left: 50px !important;
        }
          .login-btn-bg{
            background-color:#ffb900;
            padding:10px 10px !important;
          }
          .login-btn-bg:hover,.login-btn-bg:active,.login-btn-bg:focus{
            background-color:#f5b800 !important;
          }
          .social-login {
            margin-top: 35px;
        
        }
        .social-button {
            margin: 0 8px 5px !important;
        }
          
          `
        }
      </style>
    )
  }


