const lookerDashboards = {
  overview: {
    label: 'Overview',
    dashboardId: null,
    tags: ['general'],
  },
  marketing: {
    label: 'Marketing',
    dashboardId: 21,
    tags: ['general'],
  },
  leasing: {
    label: 'Leasing',
    dashboardId: 19,
    tags: ['general'],
  },
  management: {
    label: 'Management',
    dashboardId: null,
    tags: ['general'],
  },
  tenants: {
    label: 'Tenants',
    dashboardId: null,
    tags: ['general'],
  },
  reporting: {
    label: 'Revenue Management',
    dashboardId: 77,
    tags: ['general'],
  },
  reporting_leasing: {
    label: 'Reporting Leasing',
    dashboardId: 2,
    tags: ['general'],
  },
  management_sandbox: {
    label: 'Management Data',
    dashboardId: 10,
    tags: ['general'],
  },
  unit_turn: {
    label: 'Unit Turn Data',
    dashboardId: 11,
    tags: ['general'],
  },
  unit_turn_entrata: {
    label: 'Unit Turn',
    dashboardId: 23,
    tags: ['custom'],
  },
  marketing_leasing_entrata: {
    label: 'Marketing & Leasing',
    dashboardId: 22,
    tags: ['custom'],
  },
  marketing_leasing_sandbox: {
    label: 'Marketing & Leasing Data',
    dashboardId: 9,
    tags: ['general'],
  },
  budgeted_vs_actual: {
    label: 'Budgeted vs. Actual',
    dashboardId: 12,
    tags: ['general'],
  },
  asset_management: {
    label: 'Asset Management',
    dashboardId: 30,
    tags: ['general'],
  },
  cgi_vp_exec: {
    label: 'VP Exec - Visualizations',
    dashboardId: 45,
    tags: ['custom'],
  },
  cgi_leasing: {
    label: 'Marketing and Leasing',
    dashboardId: 33,
    tags: ['custom'],
  },
  cgi_vp_exec_sandbox: {
    label: 'VP Exec - Tables',
    dashboardId: 44,
    tags: ['custom'],
  },
  cgi_operations: {
    label: 'Operations',
    dashboardId: 46,
    tags: ['custom'],
  },
  standard_operations: {
    label: 'Operations',
    dashboardId: 76,
    tags: ['custom'],
  },
  standard_occupancy_and_leasing_performance: {
    label: 'Occupancy and Leasing Performance',
    dashboardId: 75,
    tags: ['custom'],
  },
  standard_marketing: {
    label: 'Marketing',
    dashboardId: 74,
    tags: ['custom'],
  },
  standard_financial: {
    label: 'Financial',
    dashboardId: 73,
    tags: ['custom'],
  },
  standard_revenue_management: {
    label: 'Revenue Management',
    dashboardId: 77,
    tags: ['custom'],
  },
  standard_executive_overview: {
    label: 'Executive Overview',
    dashboardId: 68,
    tags: ['custom'],
  },
};

export default lookerDashboards;
