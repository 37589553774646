
import {Alert} from 'rsuite';

export function handleErrorStatusCode(axiosError, operationNameOrStatusCodeToErrorMessageMap, customHandlerIfErrorNotHanlded, alwaysExecuteErrorhandler,errorTracker, errorTrackerData, rethrowError){
	let errorWasHandled =  false;
	const operationName =  typeof operationNameOrStatusCodeToErrorMessageMap === "string"?operationNameOrStatusCodeToErrorMessageMap: null;
	const statusCodeToErrorMessageMap = typeof operationNameOrStatusCodeToErrorMessageMap !== "string"?operationNameOrStatusCodeToErrorMessageMap: null;
	try{
		let errorMessage = null;
		if(typeof axiosError === 'string'){
			errorMessage = axiosError;
		}else{
			if(502 === axiosError.response.status){
				if(statusCodeToErrorMessageMap && statusCodeToErrorMessageMap[502]){
					errorMessage = statusCodeToErrorMessageMap[502];
				}else{
					errorMessage = `Something went wrong on our side${operationName?" on " +operationName:""}. Please try again!`
				}

			}
		}
		if(errorMessage){
			Alert.error(errorMessage, 5000);
			console.error(errorMessage);
			errorWasHandled = true;
		}
		
		if(errorTracker){
			errorTracker.error(operationName? operationName +" Error" :"Error", errorTrackerData);
		}
	}catch(error){
		console.error(error);
	}
	if(!errorWasHandled && customHandlerIfErrorNotHanlded){
		const result =  customHandlerIfErrorNotHanlded(axiosError);
		if(!alwaysExecuteErrorhandler){
			return result;
		}
	}
	if(alwaysExecuteErrorhandler){
		return alwaysExecuteErrorhandler(axiosError);
	}
	if(rethrowError){
		throw axiosError;
	}
}

export function createErrorStatusCodeHandler(operationNameOrStatusCodeToErrorMessageMap, customHandlerIfErrorNotHanlded, alwaysExecuteErrorhandler , errorTracker, errorTrackerData, rethrowError){
	return (axiosError)=>{
		return handleErrorStatusCode(axiosError, operationNameOrStatusCodeToErrorMessageMap,customHandlerIfErrorNotHanlded, alwaysExecuteErrorhandler, errorTracker, errorTrackerData, rethrowError);
	}
}

