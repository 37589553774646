import { AuthApi, UserApi } from 'services/api';
import React, { useState, useEffect } from 'react';
import { Alert } from 'rsuite';
import Form from '../../components/Form/index';
import PropTypes from 'prop-types';
import { useUser } from 'contexts/UserContext';
import Cookies from 'universal-cookie';
import { useErrorTracker } from 'utils/use-error-tracker';

export function Auth({ isPopup, mutateUser, setformAction, tenantLogin = false, customRedirect, setEmail = '' }) {
  const errorTracker = useErrorTracker();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutateUser: muHandler } = isPopup ? { mutateUser } : useUser();
  useEffect(() => {
    const cookies = new Cookies();
    cookies.set('loginTo', isPopup ? `${window.location.pathname}${window.location.search}` : '/dashboard/bookings', {
      path: '/',
    });
  }, []);

  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({ email: setEmail, passvghgvword: '' }); //If email is provided, set it as default value

  const onFormSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    AuthApi.login(formValue)
      .then(() => {
        UserApi.getCurrentUser()
          .then(user => {
            muHandler(user);
            //For tenant login, use custom redirect function which will send them to my-booking page
            if (customRedirect) {
              customRedirect(user);
            }
          })
          .catch(err => {
            errorTracker.error('Get current user error');
            Alert.error(
              `Something went wrong while fetching user data. We're on it. Please try again after a few minutes.`,
              5000
            );
            console.error(err);
          });
      })
      .catch(err => {
        if (err.response.status >= 400 && err.response.status < 500) {
          errorTracker.error('Log in authentication error', { loginData: formValue });
          Alert.error('Invalid email or password!', 5000);
        } else {
          errorTracker.error('Log in error', { loginData: formValue });
          Alert.error(
            `Something went wrong while logging you in. We're on it. Please try again after a few minutes.`,
            5000
          );
        }
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form
      login
      titleText="Welcome"
      buttonText="Login"
      belowButtonText={isPopup ? "Don't have an account? Signup" : "Don't have an account? Request a product demo"}
      belowButtonLink={isPopup ? '/signup' : 'https://www.leni.co/request-demo'}
      handleSubmit={onFormSubmit}
      isLoading={loading}
      onChangeFormValue={setFormValue}
      formValue={formValue}
      isPopup={isPopup}
      setformAction={setformAction}
      showLink={!tenantLogin}
      setEmail={setEmail}
    />
  );
}

Auth.propTypes = {
  showcasesData: PropTypes.object,
  getShowcases: PropTypes.func,
};

export default Auth;
