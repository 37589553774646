import React, { useState } from 'react';

import { Alert } from 'rsuite';
import { AuthApi } from 'services/api/auth';
import Form from '../../components/Form/index';
import { compose } from 'redux';
import { useErrorTracker } from 'utils/use-error-tracker';

export function Auth({ isPopup, setformAction }) {
  const errorTracker = useErrorTracker();
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({ email: '' });

  const onFormSubmit = event => {
    event.preventDefault();
    setLoading(true);
    AuthApi.generateResetPasswordLink(formValue)
      .then(() => {
        Alert.success('Please check your email for the reset password link');
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        errorTracker.error('Generate reset password link error', { generateResetPasswordLinkData: formValue });
        Alert.error('Something went wrong while generating reset password link. This user might not exist!', 5000);
        console.error(err);
      });
  };

  return (
    <Form
      forgotPassword
      titleText="Let's get you back in the game!"
      buttonText="Get Recovery Link"
      belowButtonText="I can recall it now! Login"
      belowButtonLink="/login"
      handleSubmit={onFormSubmit}
      isLoading={loading}
      onChangeFormValue={setFormValue}
      formValue={formValue}
      isPopup={isPopup}
      setformAction={setformAction}
    />
  );
}

export default compose(Auth);
